import { Component, Vue, Prop } from 'vue-property-decorator'
import MultiInputNew from '@/components/MultiInput/MultiInputNew.vue'
import MultiInput from '@/components/MultiInput/MultiInput.vue'
import { Content, Matrix } from '@/Models'
import { ValidationObserver } from 'vee-validate'
import matrixModule from '@/store/matrix'
import CardList from '@/components/Quadrant/CardList.vue'
import draggable from 'vuedraggable'
@Component({
  components: {
    MultiInput,
    MultiInputNew,
    ValidationObserver,
    CardList,
    draggable,
  },
})
export default class Quadrant extends Vue {
  @Prop({ required: true })
  quadrant_name!: string
  @Prop({ required: true })
  type!: string
  quadrants: Content[] = []
  quadrant_content = new Content()
  quadrant_content_index = -1
  index_temp = 0
  showEdit = false
  dragging = false

  mounted() {
    let matrix = matrixModule.matrix
    this.quadrant_content = new Content()
    if (matrix && this.quadrant_name) {
      matrix = matrix as Matrix
      let data = matrix.resources_quadrant
      if (this.quadrant_name === 'west_quadrant') data = matrix.west_quadrant
      if (this.quadrant_name === 'east_quadrant') data = matrix.east_quadrant
      if (this.quadrant_name === 'north_quadrant') data = matrix.north_quadrant
      if (this.quadrant_name === 'south_quadrant') data = matrix.south_quadrant
      this.quadrants = data && data.content ? data.content : []
      //Se asigna un orden inicial
      this.quadrants = this.quadrants.map((x: any, index) => ({
        ...x,
        order: x.order == null ? index : x.order,
      }))
    }
  }

  checkMove(e: any) {
    this.quadrants[e.draggedContext.index]['order'] =
      e.draggedContext.futureIndex
  }

  async add() {
    const observer = this.$refs[`observer_${this.quadrant_name}`] as any
    if (!observer) return
    const isValid = await observer.validate()
    if (isValid) {
      this.quadrants.splice(0, 0, this.quadrant_content)
      this.quadrant_content = new Content()
      observer.reset()
      this.$set(this, 'quadrants', this.quadrants)
    }
  }

  deleteQuadrant(data: { index: number }) {
    this.quadrants = this.quadrants.filter((text, index) => index != data.index)
  }

  getData() {
    let matrix = matrixModule.matrix
    if (matrix && this.quadrant_name) {
      matrix = matrix as Matrix
      let data = matrix.resources_quadrant
      if (this.quadrant_name === 'west_quadrant') data = matrix.west_quadrant
      if (this.quadrant_name === 'east_quadrant') data = matrix.east_quadrant
      if (this.quadrant_name === 'north_quadrant') data = matrix.north_quadrant
      if (this.quadrant_name === 'south_quadrant') data = matrix.south_quadrant
      matrix = {
        ...matrix,
        [this.quadrant_name]: {
          ...data,
          content: this.quadrants,
        },
      }
    }
    return matrix
  }

  updateContent(data: { index: number }) {
    this.quadrant_content_index = data.index
    this.quadrant_content = {
      ...this.quadrants[data.index],
    }
    this.showEdit = true
  }

  async confirmEdit() {
    const observer = this.$refs[`observer_${this.quadrant_name}`] as any
    if (!observer) return
    const isValid = await observer.validate()
    if (isValid) {
      this.quadrants[this.quadrant_content_index] = {
        ...this.quadrant_content,
      }
      this.quadrant_content = new Content()
      observer.reset()
      this.$set(this, 'quadrants', this.quadrants)
      this.showEdit = false
    }
  }

  cancelEdit() {
    const observer = this.$refs[`observer_${this.quadrant_name}`] as any
    if (!observer) return
    this.quadrant_content_index = -1
    this.quadrant_content = new Content()
    observer.reset()
    this.showEdit = false
  }

  async validate() {
    return this.quadrants.length ? this.getData() : null
  }
}
